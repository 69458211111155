/* eslint-disable max-lines */
import itiriri from 'itiriri';
import {DateUtils, Interval} from '../../../core/utils/dateUtils';
import React, {useEffect, useMemo, useState} from 'react';
import {Grid} from '../../../kit/Grid';
import {useSyncCenter} from '../../../syncstream/SyncCenterProvider';
import {useMap, useStore} from '../../../core/storage/hooks/UseStore';
import {DashboardMenu} from './components/DashboardMenu';
import {DashboardNavbar} from './components/DashboardNavbar';
import {DashboardResidentsGrid} from './components/DashboardResidentsGrid';
import {Redirect, useHistory, useParams} from 'react-router-dom';
import {useAppState} from '../../../context/AppStateProvider';
import styled from 'styled-components';
import {useApiUtils} from '../../../syncstream/utils/hooks/useApiUtils';
import {HSDrug, HSFacilityGroup, HSPatient, ReasonCode} from 'server-openapi';
import {RequirePermission} from '../../../components/RequirePermission/RequirePermission';
import {useHealthCheck} from '../../../core/healthcheck/HealthCheckProvider';
import {toasts} from '../../../kit/Toasts/Toaster';
import {debounce} from 'lodash-es';
import {RoundScheduleItem} from '../../../syncstream/utils/RoundUtils';
import {endOfDay, startOfDay} from "date-fns";
import {RestrictedDashboardNavbar} from "./components/RestrictedDashboardNavbar";
import {useGetScheduleFromParameters} from "../../../syncstream/utils/hooks/GetSchedule";
import {useDashboardStore} from "../DashboardContext";
import {useGroupPermissions} from "../../../core/authz/PermissionsProvider";
import {Logger} from "../../../core/logger/logger";
import {PatchUtils} from "../../ResidentDetails/components/patches/PatchUtils";
import {DrugUtils} from "../../../syncstream/utils/DrugUtils";

interface IParams {
  facilityGroupId: string;
}

export enum FilterDrugType {
  Controlled = 'Controlled',
  NonControlled = 'Non-Controlled',
  TimeCritical = 'Time critical',
  Insulin = 'Insulin',
}

export enum FilterDrugTypeControlled {
  All = 'All',
  Controlled = 'Controlled',
  NonControlled = 'Non-Controlled',
  Administrable = 'Administrable'
}

export enum FilterDrugTypeOther {
  //None = 0,
  TimeCritical = 1 << 0,      // 00001 -- the bitshift is unnecessary, but done for consistency
  Insulin = 1 << 1,           // 00010
  Patch = 1 << 2,             // 00100
  SyringeDriver = 1 << 3,     // 01000
  Injection = 1 << 4,         // 10000
  All = ~(~0 << 5)            // 11111
}

export enum DrugTypeFilters {
  None = 0,
  Controlled = 1 << 0,      // 0001 -- the bitshift is unnecessary, but done for consistency
  NonControlled = 1 << 1,   // 0010
  TimeCritical = 1 << 2,    // 0100
  Insulin = 1 << 3,         // 1000
  All = ~(~0 << 4)          // 1111
}

export interface IDashboardPatient {
  name: string;
  wardId?: number;
  wardName: string;
  isControlled: boolean;
  hasInsulin: boolean;
  hasLateDoses: boolean;
  isTimeCritical: boolean;
  hasRefused: boolean;
  hasWithheld: boolean;
  hasOther: boolean;
  hsPatient: HSPatient;
  hasMedChanges: boolean;
  scheduleItems: RoundScheduleItem[];
  hasPatch: boolean;
  hasInjection: boolean;
}

// eslint-disable-next-line max-lines-per-function
export function DashboardPage() {
  const logger = new Logger('Dashboard');
  logger.info('Rendering');
  const services = useSyncCenter();
  const { facilityGroupId } = useParams<IParams>();
  const appState = useAppState();
  const history = useHistory();
  const isOnline = useHealthCheck().isHealthy;
  const apiUtils = useApiUtils();
  const facilityGroupsStore = useStore(services.facilityGroups.store).store;
  const facilitiesStore = useStore(services.facilities.store).store;
  const drugsMap = useMap(services.drugs);
  const getScheduleFromParameters = useGetScheduleFromParameters();
  const groupPermissions = useGroupPermissions();

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const getDashboardPatientsFromWindowMemo = useMemo(() => (roundWindow: Interval) => {
    if (!facilityGroupId) {
      return [];
    }

    const facilityIds = itiriri(facilitiesStore.values())
      .filter((f) => f.facilityGroupId === +facilityGroupId)
      .map((f) => f.hsId!)
      .toArray();

    const patients = apiUtils.patients
      .getActivePatients()
      .filter((p) => facilityIds.includes(p.facility!))
      .toArray();

    if (!patients) {
      return [];
    }

    const roundDetails: RoundScheduleItem[] = getScheduleFromParameters(roundWindow, facilityIds);

    const todayScheduleItems: RoundScheduleItem[] = getScheduleFromParameters({
      start: DateUtils.fromDate(startOfDay(new Date())),
      end: DateUtils.fromDate(endOfDay(new Date()))
    }, facilityIds);

    const result: IDashboardPatient[] = [];
    for (const patient of patients.values()) {
      const reasonCodes = apiUtils.patients.getReasonCodesFromAdministeredDoses(
          patient,
          new Date(),
          apiUtils.patients.getAdministeredDosesByRound(new Date(), patient.hsId!),
      );

      const dashboardPatient: IDashboardPatient = {
        hasInjection: false,
        hasPatch: false,
        hsPatient: patient,
        name: apiUtils.patients.getDisplayPatientName(patient),
        wardId: patient.facility,
        wardName: facilitiesStore.get(patient.facility!.toString())?.name ?? '',
        hasRefused: reasonCodes.some((reasonCode) => reasonCode === ReasonCode.Refused),
        hasWithheld: reasonCodes.some((reasonCode) => reasonCode === ReasonCode.Withheld),
        hasOther: reasonCodes.some((reasonCode) => reasonCode === ReasonCode.Other),
        hasInsulin: false,
        hasLateDoses: reasonCodes.some((reasonCode) => reasonCode === ReasonCode.DosedLate),
        isTimeCritical: false,
        isControlled:
            apiUtils.patients.patientHasControlledDrugs(patient) || apiUtils.patients.patientHasControlledPrns(patient),
        hasMedChanges: apiUtils.patients.fetchChangedMedicationDetailsFromPatient(patient).medicationHasChanged,
        scheduleItems: roundDetails.filter((roundScheduleItem) => roundScheduleItem.patient.hsId === patient.hsId)
      };

      dashboardPatient.hasInsulin = todayScheduleItems.some(roundScheduleItem => {
        if (roundScheduleItem.patient.hsId !== dashboardPatient.hsPatient.hsId) {
          return false;
        }
        const drug = drugsMap.get(roundScheduleItem.packedMedication?.drugHsId?.toString() ?? '');
        return drug && drug.isInsulin;
      });
      dashboardPatient.isTimeCritical = todayScheduleItems.some(roundScheduleItem => (roundScheduleItem.patient.hsId === dashboardPatient.hsPatient.hsId) && roundScheduleItem.packedMedication?.timeCritical);
      dashboardPatient.hasPatch = todayScheduleItems.some(roundScheduleItem => {
          if (dashboardPatient.hsPatient.hsId === roundScheduleItem.patient.hsId) {
            const drug = drugsMap.get(roundScheduleItem.packedMedication.drugHsId?.toString() ?? '');

            return PatchUtils.isPatch(drug);
          }
        }
      );
      dashboardPatient.hasInjection = todayScheduleItems.some(roundScheduleItem => {
        if (dashboardPatient.hsPatient.hsId === roundScheduleItem.patient.hsId) {
          const drug = drugsMap.get(roundScheduleItem.packedMedication.drugHsId?.toString() ?? '');

          // Selecting Injection filter includes all injections
          return DrugUtils.isInjection(drug as HSDrug, roundScheduleItem.packedMedication.route?.code as string)
        }
      })

      result.push(dashboardPatient);
    }

    const returnvalue = result.sort((a, b) => {
      let result = (a.wardName ?? '').localeCompare(b.wardName ?? '');
      if (result !== 0) {
        return result;
      }
      result = (a.hsPatient?.familyName ?? '').localeCompare(b.hsPatient?.familyName ?? '');
      if (result !== 0) {
        return result;
      }
      return (a.hsPatient?.givenName ?? '').localeCompare(b.hsPatient?.givenName ?? '');
    });
    return returnvalue;
  }, [roundWindow]);

  function getDashboardPatientsFromWindow(roundWindow: Interval): IDashboardPatient[] {
    if (!facilityGroupId) {
      return [];
    }
    const facilityIds = itiriri(facilitiesStore.values())
        .filter((f) => f.facilityGroupId === +facilityGroupId)
        .map((f) => f.hsId!)
        .toArray();

    const patients = apiUtils.patients
        .getActivePatients()
        .filter((p) => facilityIds.includes(p.facility!))
        .toArray();
    if (!patients) {
      return [];
    }
    const roundDetails: RoundScheduleItem[] = getScheduleFromParameters(roundWindow, facilityIds);
    const todaysScheduleItems: RoundScheduleItem[] = getScheduleFromParameters({
      start: DateUtils.fromDate(startOfDay(new Date())),
      end: DateUtils.fromDate(endOfDay(new Date()))
    }, facilityIds);
    const result: IDashboardPatient[] = [];
    for (const patient of patients.values()) {

      const reasonCodes = apiUtils.patients.getReasonCodesFromAdministeredDoses(
          patient,
          new Date(),
          apiUtils.patients.getAdministeredDosesByRound(new Date(), patient.hsId!),
      );
      const dashboardPatient: IDashboardPatient = {
        hsPatient: patient,
        name: apiUtils.patients.getDisplayPatientName(patient),
        wardId: patient.facility,
        wardName: facilitiesStore.get(patient.facility!.toString())?.name ?? '',
        hasRefused: reasonCodes.some((x) => x === ReasonCode.Refused),
        hasWithheld: reasonCodes.some((x) => x === ReasonCode.Withheld),
        hasOther: reasonCodes.some((x) => x === ReasonCode.Other),
        hasInsulin: false,
        hasLateDoses: reasonCodes.some((x) => x === ReasonCode.DosedLate),
        isTimeCritical: false,
        isControlled:
            apiUtils.patients.patientHasControlledDrugs(patient) || apiUtils.patients.patientHasControlledPrns(patient),
        hasMedChanges: apiUtils.patients.fetchChangedMedicationDetailsFromPatient(patient).medicationHasChanged,
        scheduleItems: roundDetails.filter((x) => x.patient.hsId === patient.hsId),
        hasPatch: false,
        hasInjection: false,
      };
      dashboardPatient.hasInsulin = todaysScheduleItems.some(x => {
        if (x.patient.hsId !== dashboardPatient.hsPatient.hsId) {
          return false;
        }
        const drug = drugsMap.get(x.packedMedication?.drugHsId?.toString() ?? '');
        return drug && drug.isInsulin;
      });
      dashboardPatient.isTimeCritical = todaysScheduleItems.some(x => (x.patient.hsId === dashboardPatient.hsPatient.hsId) && x.packedMedication?.timeCritical);
      result.push(dashboardPatient);
    }
    const returnvalue = result.sort((a, b) => {
      let result = (a.wardName ?? '').localeCompare(b.wardName ?? '');
      if (result !== 0) {
        return result;
      }
      result = (a.hsPatient?.familyName ?? '').localeCompare(b.hsPatient?.familyName ?? '');
      if (result !== 0) {
        return result;
      }
      return (a.hsPatient?.givenName ?? '').localeCompare(b.hsPatient?.givenName ?? '');
    });

    return returnvalue;
  }

  // facility group is the actual building, facilities are wings  // facility group is the actual building, facilities are wings
  const facilityGroups = itiriri(facilityGroupsStore.values()).toArray();
  // eslint-disable-next-line sonarjs/cognitive-complexity

  const patientsInScope = useMemo<IDashboardPatient[]>(() => {
    const window = apiUtils.rounds.getRoundWindow(new Date(), +facilityGroupId);
    return getDashboardPatientsFromWindowMemo(window);
  }, [apiUtils, facilityGroupId]);

  const todaysRemainingPatients: IDashboardPatient[] = useMemo<IDashboardPatient[]>(() => {
    const window = apiUtils.rounds.getRoundWindow(new Date(), +facilityGroupId);
    let endOfToday = endOfDay(new Date());

    if (DateUtils.toDate(window.end) > endOfToday) {
      // Cater checking patients at the end of the day.  In this case, we want to go through until the start of the window (which is tomorrow morning)
      // rather than midnight tonight.
      endOfToday = DateUtils.toDate(window.end);
    }
    const restOfTodayWindow: Interval = {
      start: window?.start ?? '',
      end:  DateUtils.fromDate(endOfToday),
    };
    return getDashboardPatientsFromWindowMemo(restOfTodayWindow);
  }, []);

  function roundWindow(): Interval | undefined {
    if (!facilityGroupId) {
      return;
    }
    return apiUtils.rounds.getRoundWindow(new Date(), +facilityGroupId);
  }

  useEffect( () => {
    if (!isOnline) {
      toasts.error("Can't switch facilities while offline");
      history.push(`/facility-group/${appState.state.selectedFacilityGroupId}`);
      return;
    }
    if (facilityGroupId && appState.state.selectedFacilityGroupId !== facilityGroupId) {
      appState.set({ isNewFacilityGroup: true, selectedFacilityGroupId: facilityGroupId, isFacilityGroupInitialSyncComplete: { id: '', complete: false } });
    }
  }, [facilityGroupId]);

  if (!facilityGroupId) {
    if (appState.state.selectedFacilityGroupId) {
      return <Redirect to={`/facility-group/${appState.state.selectedFacilityGroupId}`} />;
    } else if (facilityGroups.length > 1) {
      return <Redirect to={'/facility-group/switch'} />;
    } else if (facilityGroups[0]) {
      return <Redirect to={`/facility-group/${facilityGroups[0].hsId}`} />;
    }
  }

  const facilityGroup = facilityGroups.find((fg) => fg.hsId === parseInt(facilityGroupId));
  const canListPatients = groupPermissions.canListPatients;

  if (!facilityGroup) {
    return (
      <FacilityGroupNotFoundError>
        You do not have access to any facility groups. Please contact your system administrator for more information.
      </FacilityGroupNotFoundError>
    );
  }

  return (
    <Grid cols={1} gap={1}>
      { canListPatients ?
      <RequirePermission hasPermission={canListPatients}>
        <DashboardContent facilityGroup={facilityGroup} patientList={patientsInScope} patientsForRemainderOfDay={todaysRemainingPatients} roundWindow={roundWindow()} />
      </RequirePermission> :
      <Grid cols={1} gap={1}>
        <RestrictedDashboardNavbar facilityGroup={facilityGroup} />
      </Grid>
      }
    </Grid>
  );
}

// eslint-disable-next-line max-lines-per-function
function DashboardContent(props: {
  facilityGroup: HSFacilityGroup;
  patientList: IDashboardPatient[];
  patientsForRemainderOfDay: IDashboardPatient[];
  roundWindow: Interval | undefined;
}) {
  const [dashboardState, dashboardDispatch] = useDashboardStore();
  const { patientList, patientsForRemainderOfDay } = props;
  const services = useSyncCenter();
  const facilitiesStore = useStore(services.facilities.store).store;
  const [query, setQuery] = useState('');
  const [debouncedQuery, setDebouncedQuery] = useState('');
  const [selectedDoseStatus, setSelectedDoseStatus] = useState<ReasonCode>();
  const facilities = itiriri(facilitiesStore.values())
     .filter((f) => f.facilityGroupId === props.facilityGroup.hsId!)
     .toArray();
  const debouncedOnSearch = useMemo(() => debounce((query: string) => setDebouncedQuery(query), 500), []);
  function matchingScheduleItems(
      items: IDashboardPatient[],
      filterDrugTypeControlled: FilterDrugTypeControlled | undefined,
      filterDrugTypeOther: FilterDrugTypeOther | undefined,
  ): IDashboardPatient[] {
    if (filterDrugTypeControlled === undefined && filterDrugTypeOther === undefined) {
      return items;
    }

    if (!!filterDrugTypeControlled) {
      items = items.filter((entry) => {
        return (
            (filterDrugTypeControlled === FilterDrugTypeControlled.Administrable &&
                (entry.scheduleItems.some(s => s.packedMedication.packed)
                 || entry.scheduleItems.some(s => s.packedMedication.packType === 'Blister')
                 || !entry.isControlled))
            ||
            (filterDrugTypeControlled === FilterDrugTypeControlled.Controlled && (entry.isControlled)) ||
            (filterDrugTypeControlled === FilterDrugTypeControlled.NonControlled && (!entry.isControlled)));
      });
    }
    if (!!filterDrugTypeOther) {
      items = items.filter((entry) => {
        return ((((filterDrugTypeOther & FilterDrugTypeOther.TimeCritical) === FilterDrugTypeOther.TimeCritical) && entry.isTimeCritical) ||
            (((filterDrugTypeOther & FilterDrugTypeOther.Insulin) === FilterDrugTypeOther.Insulin) && entry.hasInsulin) ||
          (((filterDrugTypeOther & FilterDrugTypeOther.Patch) === FilterDrugTypeOther.Patch) && entry.hasPatch) ||
          (((filterDrugTypeOther & FilterDrugTypeOther.Injection) === FilterDrugTypeOther.Injection) && entry.hasInjection)
        );
      });
    }

    return items;
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  function getFilteredPatients(
    debouncedQuery: string,
    selectedFacilityIds: string[] | undefined,
    selectedDrugTypeFiltersControlled: FilterDrugTypeControlled | undefined,
    selectedFilterDrugTypeOther: FilterDrugTypeOther | undefined,
    selectedDoseStatus: ReasonCode | undefined,
    patientList: IDashboardPatient[],
  ): IDashboardPatient[] {

    const wardPatientList = patientList.filter(
        (patient) =>
            (
                (!selectedFacilityIds || selectedFacilityIds.includes(patient.wardId!.toString())) &&
                patient.name.toUpperCase().includes(debouncedQuery.toUpperCase()) &&
                (!selectedDoseStatus ||
                    (selectedDoseStatus === ReasonCode.Withheld && patient.hasWithheld) ||
                    (selectedDoseStatus === ReasonCode.Refused && patient.hasRefused) ||
                    (selectedDoseStatus === ReasonCode.DosedLate && patient.hasLateDoses))

            ));
    const matchingScheduleItemsList = matchingScheduleItems(wardPatientList, selectedDrugTypeFiltersControlled, selectedFilterDrugTypeOther);

    return matchingScheduleItemsList;
  }

  // Filter patients by search query
  const filteredPatients = useMemo(
    () => getFilteredPatients(debouncedQuery, dashboardState.filterWards, dashboardState.filterDrugTypeControlled, dashboardState.filterDrugTypeOther, selectedDoseStatus, patientList),
    [debouncedQuery, dashboardState, selectedDoseStatus, patientList],
  );

  const filteredPatientsForRemainderOfDay = useMemo(
      () => getFilteredPatients(debouncedQuery, dashboardState.filterWards, dashboardState.filterDrugTypeControlled, dashboardState.filterDrugTypeOther, selectedDoseStatus, patientsForRemainderOfDay),
      [debouncedQuery, dashboardState, selectedDoseStatus, patientsForRemainderOfDay],
  );

  function hasDosesInWindow(): boolean | undefined {
    if (!patientList) {
      return;
    }
    // If any patient has a schedule item, then they have a dose in the window.
    // Note that we are using the full list - not the filtered list here, as when we go to the create
    // round page, we don't pass through the filter.
    return patientList.some((x) => x.scheduleItems && x.scheduleItems.length > 0);
  }

  // All the schedule items from the start of the current window, till the end of day.
  // Used to work out the next dose time.
  function getNextDoseRound(): Date | undefined {
    if (!filteredPatientsForRemainderOfDay || filteredPatientsForRemainderOfDay.length === 0) {
      return;
    }

    const doseTimes = filteredPatientsForRemainderOfDay
      .flatMap((x) => x.scheduleItems)
      .flatMap((x) =>
        x.scheduledActivity?.time
          ? x.scheduledActivity.time
          : DateUtils.toOffsetlessDate(x.packedMedication.doseTimestamp!),
      );
    if (doseTimes.length === 0) {
      return;
    }
    // Get the earliest.
    return doseTimes.reduce((a, b) => {
      return a < b ? a : b;
    });
  }


  return (
    <Grid cols={1} gap={1}>
      <DashboardNavbar facilityGroup={props.facilityGroup} nextDoseRound={getNextDoseRound()} />
      <DashboardMenu
        hasDosesInWindow={!!hasDosesInWindow()}
        facilityGroup={props.facilityGroup}
        searchQuery={query}
        onSearch={(newQuery) => {
          setQuery(newQuery);
          debouncedOnSearch(newQuery);
        }}
        filterDrugTypeControlled={dashboardState.filterDrugTypeControlled}
        onSelectDrugTypeControlled={(drugType: FilterDrugTypeControlled) => {
          dashboardDispatch({
            type: "SET_CONTROLLED_DRUG_TYPE_FILTER",
            payload: drugType
          })
        }}
        filterDrugTypeOther={dashboardState.filterDrugTypeOther}
        onSelectDrugTypeOther={(drugType: FilterDrugTypeOther | undefined) => {
          dashboardDispatch({
            type: "SET_OTHER_DRUG_TYPE_FILTER",
            payload: drugType
          })
        }}
        selectedFacilityIds={dashboardState.filterWards}
        onSelectFacilities={(facilities) => {
          dashboardDispatch({
            type: "SET_WARD_FILTER",
            payload: facilities
          })
        }}
        selectedDoseStatus={selectedDoseStatus}
        onSelectDoseStatus={(doseStatus) => setSelectedDoseStatus(doseStatus)}
      />
      <DashboardResidentsGrid
        residentGridArray={filteredPatients.map((patient) => {
          return {
            patient: patient.hsPatient,
            facilityWing: facilities.find((f) => f.hsId === patient.wardId)?.name ?? 'unknown wing',
            hasMedChanges: patient.hasMedChanges,
            scheduleItems: patient.scheduleItems
          };
        })}
      />
    </Grid>
  );
}

const FacilityGroupNotFoundError = styled.div`
  color: ${(props) => props.theme.backgrounds.default.fg};
  padding: 20px;
`;
