import React, { useState } from 'react';
import { Layout } from '../../kit/Layout';
import { LayoutContainer, ViewSize } from '../../kit/LayoutContainer';
import { colors } from '../../kit/Theme/Theme';
import { Text } from '../../kit/Text';
import { PuffLoader } from 'react-spinners';

interface IProps {
    message: string;
}
export function LoadingScreen(props : IProps) {
  return (
    <LayoutContainer
      size={ViewSize.Medium}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '95vh',
      }}
    >
      <Layout gap={1} justify={'center'} align={'center'} style={{ color: 'white' }}>
        <PuffLoader color={colors.bg_white} />
        <Layout gap={0.5} justify={'center'} align={'center'}>
          <Text size={'medium'} weight={'bold'}>
            Loading MediSphere…
          </Text>
          <Text weight={'100'}>Making sure your medication information is up to date</Text>
          <Text weight={'100'}>{props.message}</Text>
        </Layout>
      </Layout>
    </LayoutContainer>
  );
}
