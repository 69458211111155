/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
/* eslint-disable sonarjs/no-identical-functions */
import itiriri from 'itiriri';
import {
  AdHocReasonCode,
  FacilityGroupConfigurationDto,
  HSAdministeredAdHocDrug,
  HSAdministeredAdHocDrugComment,
  HSAdministeredAdHocDrugOutcome, HSAdministeredDose,
  HSAdministeredDrug,
  HSAdministeredDrugComment,
  HSAdministeredDrugOutcome,
  HSConsiderations,
  HSDoseRound,
  HSDrug,
  HSFacility,
  HSMedication,
  HSPackedMedication,
  HSPackedPatientDay,
  HSPackedPatientPrnMedication,
  HSPackedPrnMedication,
  HSPatchObservation,
  HSPatient,
  HSPatientProfile,
  ListSecondCheckSettingDto,
  MedicationType,
  NimAvailableDrugDto,
  PackType,
  ReasonCode,
  SyringeDriverActivityKind,
} from 'server-openapi';
import { groupBy } from 'lodash-es';
import { DateUtils } from '../../core/utils/dateUtils';
import { PatchLocationDTO } from '../../pages/ResidentDetails/components/patches/PatchLocations';
import { User } from 'oidc-client';
import { PatientUtils } from './PatientUtils';
import { PatchUtils } from '../../pages/ResidentDetails/components/patches/PatchUtils';
import { RoundScheduleItem, RoundUtils, ScheduledActivity } from './RoundUtils';
import { SecondCheckableMedication } from '../../pages/ResidentDetails/components/MedicationListsTabbedRouter/MedicationListsTabbedRouter';
import { MedisphereTestResult } from '../SyncTestResults';
import { MedisphereSyringeDriverActivity } from '../SyncSyringeDriverActivity';
import { endOfDay, isSameDay, startOfDay, subDays } from 'date-fns';
import { PatchStatus } from '../../pages/ResidentDetails/components/MedicationListsTabbedRouter/TabLists/PatchesMedicationList';
import _ from 'lodash';
import { SyncPatients } from '../SyncPatients';
import { MemoryCache } from '../../core/storage/MemoryCache';
import { patientProfileChangeString } from '../../pages/ResidentDetails/components/sidebar/PatientNotes';

export interface ResidentDetailsUtilStores {
  roundStore: ReadonlyMap<string, HSDoseRound>;
  drugStore: ReadonlyMap<string, HSDrug>;
  patientStore: ReadonlyMap<string, HSPatient>;
  facilityStore: ReadonlyMap<string, HSFacility>;
  packedDayStore: ReadonlyMap<string, HSPackedPatientDay>;
  packedPrnStore: ReadonlyMap<string, HSPackedPatientPrnMedication>;
  secondCheckStore: ReadonlyMap<string, ListSecondCheckSettingDto>;
  nimAvailableDrugStore: ReadonlyMap<string, NimAvailableDrugDto>;
  testResultsStore: ReadonlyMap<string, MedisphereTestResult>;
  syringeDriverActivityStore: ReadonlyMap<string, MedisphereSyringeDriverActivity>;
  patchObservationStore: ReadonlyMap<string, HSPatchObservation>;
  facilityGroupConfigurationStore: ReadonlyMap<string, FacilityGroupConfigurationDto>;
}

export class ResidentDetailsUtils {
  private stores: ResidentDetailsUtilStores;
  constructor(apiStores: ResidentDetailsUtilStores) {
    this.stores = apiStores;
  }
  public getGroupedMedications = (
      packedMedications: HSPackedMedication[],
      profiles: HSPatientProfile[],
      selectedDate: Date,
      patientId: number,
      facilityId: number,
  ) =>
      getGroupedMedications(
          packedMedications,
          profiles,
          patientId,
          facilityId,
          selectedDate,
          this.stores.packedDayStore,
          this.stores.patchObservationStore,
          this.stores.drugStore,
          this.stores.roundStore,
          this.stores.syringeDriverActivityStore,
          this.stores.facilityStore,
          this.stores.facilityGroupConfigurationStore,
          this.stores.patientStore,
      );
  public packTypeToString = packTypeToString;

  public getPreviousDosedDrugsForPackedPatientMedications = (packedMedications: HSPackedMedication[]) =>
      getPreviousDosedDrugsForPackedPatientMedications(packedMedications, this.stores.roundStore);

  public getDosedDrugForPreviousDoseTime = (patient: HSPatient | undefined, packedMedication?: HSPackedMedication) =>
      getDosedDrugForPreviousDoseTime(patient, packedMedication, this.stores.roundStore, this.stores.packedDayStore);

  public getPreviousDoseTimeForPatient = (patient: HSPatient | undefined, packedMedication: HSPackedMedication | undefined) =>
      getPreviousDoseTimeForPatient(patient, packedMedication, this.stores.packedDayStore);

  public reasonCodeToString = getReasonCode;
  public generateAdministeredDrug = generateAdministeredDrug;
  public generateAdministeredAdHocDrug = generateAdministeredAdHocDrug;
  public getDisplayableNoteInfosForFacilityGroupId = (facilityGroupId: string) =>
      getDisplayableNoteInfosForFacilityGroupId(
          facilityGroupId,
          this.stores.facilityStore,
          this.stores.patientStore,
          this.stores.roundStore,
          this.stores.drugStore,
          this.stores.packedDayStore,
          this.stores.packedPrnStore,
      );
  public getDisplayableNoteInfosForPatient = (
      patient: HSPatient,
      patientPrnMedications: HSPackedPrnMedication[],
      noteType?: string,
  ) =>
      getDisplayableNoteInfosForPatient(
          patient,
          this.stores.roundStore,
          this.stores.drugStore,
          patientPrnMedications,
          this.stores.packedDayStore,
          noteType,
      );
  public getMedicationTypeString = getMedicationTypeString;
  public medicationInSecondCheckSettings = medicationInSecondCheckSettings;
  public getTotalDoseAmounts = (medications: HSPackedMedication[] | HSPackedPrnMedication[]) =>
      getTotalDoseAmounts(medications);
  public getMedicationHeader = (medicationList: MedicationGroup, isPatches?: boolean) =>
      getMedicationHeader(medicationList, isPatches);

  public isMedicationSecondCheckable = (facilityGroupId: number, excludePackableMeds: boolean, packedMedication: HSPackedMedication | HSPackedPrnMedication | NimAvailableDrugDto) =>
      isMedicationSecondCheckable(this.stores.secondCheckStore, this.stores.drugStore, facilityGroupId, excludePackableMeds, packedMedication);

  public getSecondCheckableMedication = (
      facilityGroupId: number,
      patient: HSPatient,
      packedMedication: HSPackedMedication[],
      patchPackedMedication: HSPackedMedication[],
      excludePackableMeds: boolean,
      roundSchedule?: RoundScheduleItem[],
  ) =>
      getSecondCheckableMedication(
          this.stores.packedPrnStore,
          this.stores.secondCheckStore,
          this.stores.drugStore,
          this.stores.nimAvailableDrugStore,
          facilityGroupId,
          patient,
          packedMedication,
          patchPackedMedication,
          excludePackableMeds,
          roundSchedule,
      );
  public getTestResultsForAdministeredDrug = (administeredDrug: HSAdministeredDrug | HSAdministeredAdHocDrug) =>
      getTestResultsForAdministeredDrug(this.stores.testResultsStore, administeredDrug);
  public getLatestAdministrationForScheduledDrug = (packedMedication: HSPackedMedication) =>
      getLatestAdministrationForScheduledDrug(this.stores.roundStore, packedMedication);
  public getUpdateLogText = (patient: HSPatient, fieldName: string) => getUpdateLogText(patient, fieldName);
  public getSpecialConsiderationText = (specialConsiderations: HSConsiderations, swallowDifficulty: boolean) =>
      getSpecialConsiderationText(specialConsiderations, swallowDifficulty);
  public addPatientProgressNote = async (
      patient: HSPatient,
      facilityGroupId: number,
      subject: string,
      comment: string,
      attachmentReference: string,
      patientsService: SyncPatients,
      user: User,
  ) => addPatientProgressNote(patient, facilityGroupId, subject, comment, attachmentReference, patientsService, user);
  public updateAllPatientMedicationSAStatus = async (patientMemoryCache: MemoryCache<HSPatient>, patientId: string) =>
      await updateAllPatientMedicationSAStatus(patientMemoryCache, patientId);
  public getActivePatches = (patientId: number, selectedDate: Date, packedPatientDays: HSPackedPatientDay[]) =>
      getActivePatches(
          patientId,
          selectedDate,
          packedPatientDays,
          this.stores.patchObservationStore,
          this.stores.drugStore,
      );
}

interface ScheduledActivityAction {
  activity: ScheduledActivity;
  packedMed: HSPackedMedication;
}

export interface MedicationGroup {
  categories: MedicationCategories;
  medications: HSPackedMedication[];
  scheduledActivityAction?: ScheduledActivityAction;
}

interface MedicationCategories {
  isSyringeDriver: boolean;
  isPatch: boolean;
  packTypeString?: string;
  doseDate?: Date;
  medicationType?: MedicationType;
  profileNumber?: number;
}

export type PreviousPackedMedicationAdministeredDrugsType = {
  packedMedication: HSPackedMedication;
  mostRecentDosedDrugs: HSAdministeredDrug[];
};

export type DisplayablePatientNoteInfo = {
  patient: HSPatient;
  date: Date;
  updatedByLogin: string;
  title: string;
  comment: string;
  attachmentReference: string;
  administeredDrug?: HSAdministeredDrug | HSAdministeredAdHocDrug;
  updatedBySubjectId?: string | null;
};

// Groups Medication based on the above categories
// Outputs to a list of MedicationGroup objects each with a unique categories field
// eslint-disable-next-line max-lines-per-function
function getGroupedMedications(
    packedMedications: HSPackedMedication[],
    profiles: HSPatientProfile[],
    patientId: number,
    facilityId: number,
    selectedDate: Date,
    packedPatientDaysStore: ReadonlyMap<string, HSPackedPatientDay>,
    patchObservationStore: ReadonlyMap<string, HSPatchObservation>,
    drugStore: ReadonlyMap<string, HSDrug>,
    roundsStore: ReadonlyMap<string, HSDoseRound>,
    activityStore: ReadonlyMap<string, MedisphereSyringeDriverActivity>,
    facilityStore: ReadonlyMap<string, HSFacility>,
    facilityGroupConfigurationStore: ReadonlyMap<string, FacilityGroupConfigurationDto>,
    patientStore: ReadonlyMap<string, HSPatient>,
): MedicationGroup[] {
  const roundUtils = new RoundUtils({
    packedDayStore: packedPatientDaysStore,
    patientStore: patientStore,
    drugStore: drugStore,
    packedPrnStore: new Map(),
    roundStore: roundsStore,
    facilityStore: facilityStore,
    facilityGroupConfigurationStore: facilityGroupConfigurationStore,
    syringeDriverActivityStore: activityStore,
    patchObservationStore: patchObservationStore,
  });

  const filteredMedication = packedMedications.filter((packedMed) =>
      profiles.some(
          (profile) =>
              [...(profile.allCurrentMedications ?? []), ...(profile.recentlyDeletedMedications ?? [])].find(
                  (med) => med.hsId === packedMed.medicationId,
              )?.showOnSigningSheet,
      ),
  );

  // Groups by everything except profileNumber
  const groupedMedications: MedicationGroup[] = Object.values(
      groupBy(
          filteredMedication
              .sort((a, b) => {
                return (
                    drugStore.get(a?.drugHsId?.toString() ?? '')?.name?.localeCompare(drugStore.get(b?.drugHsId?.toString() ?? '')?.name ?? '') ?? 0
                );
              })
              .map((packedMedication) => {
                const drug = drugStore.get(packedMedication?.drugHsId?.toString() ?? '');
                const profile = profiles.find((profile) =>
                    profile.allCurrentMedications?.find((med) => med.hsId === packedMedication.medicationId),
                );
                const medication = profile?.allCurrentMedications?.find((med) => med.hsId === packedMedication.medicationId);

                // generate categories based off of defined requirements for splitting up medication
                const categories: MedicationCategories = {
                  isSyringeDriver: packedMedication?.route?.code === 'SID',
                  isPatch: PatchUtils.isPatch(drug),
                  packTypeString: packedMedication.packType
                      ? packTypeToString(packedMedication.packType, medication?.pharmacyAdded, medication, packedMedication)
                      : 'Unknown',
                  doseDate: packedMedication.doseTimestamp ? DateUtils.toDate(packedMedication.doseTimestamp) : undefined,
                  medicationType: packedMedication.medicationType,
                };

                // add it to the object temporarily to use with the groupBy method
                return { categories: categories, ...packedMedication };
              }),

          // join the object values together, as groupBy doesn't support grouping by Objects directly
          (medication) => Object.values(medication.categories).join(),
      ),

      // Map to Medication groups based off of the categories (noting that the return type will remove categories
      // from the medications field)
  ).map((group) => {
    return { categories: group[0].categories, medications: group };
  });

  // For each Medication Group, map each profileId to a new a group with incrementing profileNumber in the category
  const groupsWithProfileNumbers = groupedMedications.flatMap((group) => {
    let outputGroups: MedicationGroup[] = [];
    group.medications.forEach((medication) => {
      // Check if we've already made a group with this Id
      const matchingGroup = outputGroups.find((group) =>
          group.medications.some((groupMedication) => medication.profileHsId === groupMedication.profileHsId),
      );

      // If we have, add this group to it
      if (matchingGroup) {
        matchingGroup.medications = [...matchingGroup.medications, medication];
      }

      // If not, create a new group with a profileNumber based off of the index
      else {
        const newGroup = { categories: group.categories, medications: [medication] };
        newGroup.categories.profileNumber = outputGroups.length + 1;
        outputGroups = [...outputGroups, newGroup];
      }
    });

    // If there was only one profileId in this group, set the profileNumber to undefined to indicate that we don't need to
    // display a profile number in the UI
    if (outputGroups.length === 1) {
      outputGroups[0].categories.profileNumber = undefined;
    }

    return outputGroups;
  });

  const packedPatientDays = itiriri(packedPatientDaysStore.values())
      .filter(
          (day) =>
              day.patientId === patientId &&
              !!day.packDate &&
              subDays(startOfDay(selectedDate), 14) <= DateUtils.toOffsetlessDate(day.packDate),
      )
      .map((day) => ({
        ...day,
        packedMedications: day.packedMedications?.filter((packedMed) =>
            profiles.some(
                (profile) =>
                    [...(profile.allCurrentMedications ?? []), ...(profile.recentlyDeletedMedications ?? [])].find(
                        (med) => med.hsId === packedMed.medicationId,
                    )?.showOnSigningSheet,
            ),
        ),
      }))
      .toArray();

  const currentDayPackedDays = packedPatientDays.filter(
      (day) => day.packDate && isSameDay(DateUtils.toOffsetlessDate(day.packDate), selectedDate),
  );

  const previousDayPackedDays = packedPatientDays.filter(
      (day) => day.packDate && isSameDay(DateUtils.toOffsetlessDate(day.packDate), subDays(selectedDate, 1)),
  );

  const lastTwoPackedDayMedications = [
    ...((currentDayPackedDays.find((packedDay) => packedDay.facilityId === facilityId) ?? currentDayPackedDays[0])
        ?.packedMedications ?? []),
    ...((previousDayPackedDays.find((packedDay) => packedDay.facilityId === facilityId) ?? previousDayPackedDays[0])
        ?.packedMedications ?? []),
  ];

  // Get all syringe drivers in the last 2 days
  const recentSyringes = lastTwoPackedDayMedications.filter((med) => med.route?.code === 'SID');

  // Filter down to active syringe drivers
  //eslint-disable-next-line sonarjs/cognitive-complexity
  const startedSyringeDrivers = recentSyringes.flatMap((syringe) => {
    const administeredDrug = getLatestAdministrationForScheduledDrug(roundsStore, syringe);
    if (!administeredDrug) {
      return [];
    }
    const scheduledActivity = roundUtils
        .generateScheduledActivityForSyringeDriver(administeredDrug)
        .filter((activity) => isSameDay(activity.time, selectedDate));

    const administeredDriverActivity = itiriri(activityStore.values()).filter(
        (activity) =>
            ((activity.administeredDrugClinicalSystemId ?? false) === administeredDrug.clinicalSystemId ||
                (activity.administeredDrugId ?? false) === administeredDrug.hsId) &&
            !!activity.createdAt &&
            isSameDay(DateUtils.toDate(activity.createdAt), selectedDate),
    );

    return [
      ...administeredDriverActivity
          .map((activity) => ({
            time:
                activity.kind === SyringeDriverActivityKind.Start && syringe.doseTimestamp
                    ? DateUtils.toDate(syringe.doseTimestamp)
                    : DateUtils.toDate(activity.scheduledAt ?? activity.createdAt!),
            administeredDrugId: administeredDrug.hsId?.toString(),
            administeredDrugClinicalSystemId: administeredDrug.clinicalSystemId ?? undefined,
            kind: activity.kind!,
            syringeActivity: activity,
          }))
          .toArray()
          .filter(
              (activity) =>
                  !scheduledActivity?.some(
                      (item) => (item.syringeActivity?.clinicalSystemId ?? false) === activity.syringeActivity.clinicalSystemId,
                  ),
          ),
      ...scheduledActivity!,
    ].map((activity) => ({ packedMed: syringe, activity: activity }));
  });

  // Search for patches
  const activePatches = getActivePatches(patientId, selectedDate, packedPatientDays, patchObservationStore, drugStore);

  // Filter down to active patches
  const startedPatches: ScheduledActivityAction[] = activePatches.flatMap((patch) => {
    const administeredDrug = getLatestAdministrationForScheduledDrug(roundsStore, patch);
    if (!administeredDrug) {
      return [];
    }
    const scheduledActivity = roundUtils
        .generateScheduledActivityForPatch(patch)
        .filter((activity) => isSameDay(activity.time, selectedDate));

    const administeredPatchActivity = itiriri(patchObservationStore.values()).filter(
        (activity) =>
            (PatchUtils.getPatchOperationData(activity)?.packedMedicationId ?? false) === patch.hsId &&
            !!activity.createdAt &&
            isSameDay(DateUtils.toDate(activity.createdAt), selectedDate),
    );

    return [
      ...administeredPatchActivity
          .map((activity) => ({
            time:
                PatchUtils.getPatchOperationData(activity)?.patchStatus === PatchStatus.Applied && patch.doseTimestamp
                    ? DateUtils.toDate(patch.doseTimestamp)
                    : DateUtils.toDate(activity.createdAt!),
            packedMedicationId: patch.hsId,
            kind: PatchUtils.getPatchOperationData(activity)!.patchStatus,
            patchActivity: activity,
          }))
          .toArray()
          .filter(
              (activity) =>
                  !scheduledActivity?.some(
                      (item) => (item.patchActivity?.clinicalSystemId ?? false) === activity.patchActivity.clinicalSystemId,
                  ),
          ),
      ...scheduledActivity!,
    ].map((activity) => ({ packedMed: patch, activity: activity }));
  });

  // Filter out the active syringe drivers or patches, and patch removals from the groups
  groupsWithProfileNumbers.forEach(
      (group) =>
          (group.medications = group.medications.filter(
              (med) =>
                  !startedSyringeDrivers.some((driver) => driver.packedMed.hsId === med.hsId) &&
                  !startedPatches.some((patch) => patch.packedMed.hsId === med.hsId) &&
                  !(med.drugHsId && PatchUtils.isPatchRemoval(drugStore.get(med.drugHsId.toString())!)),
          )),
  );

  // Filter out groups that are now empty
  const filteredGroups = groupsWithProfileNumbers.filter(
      (group) => group.medications.length > 0 || group.scheduledActivityAction,
  );

  // Return those groups, and new groups with each scheduled activity mapped as it's own group
  return [
    ...filteredGroups,
    ...[...startedSyringeDrivers, ...startedPatches].map(
        (driver: { packedMed: HSPackedMedication; activity: ScheduledActivity }) => {
          const profile = profiles.find((profile) =>
              profile.allCurrentMedications?.find((med) => med.hsId === driver.packedMed.medicationId),
          );
          const medication = profile?.allCurrentMedications?.find((med) => med.hsId === driver.packedMed.medicationId);

          return {
            categories: {
              isSyringeDriver: !!driver.activity.administeredDrugId || !!driver.activity.administeredDrugClinicalSystemId,
              isPatch: !!driver.activity.packedMedicationId,
              packTypeString: driver.packedMed.packType
                  ? packTypeToString(driver.packedMed.packType, medication?.pharmacyAdded)
                  : 'Unknown',
              doseDate: driver.activity.time,
              medicationType: driver.packedMed.medicationType,
            },
            medications: [],
            scheduledActivityAction: driver,
          };
        },
    ),
  ];
}

function packTypeToString(packType?: PackType, pharmacyAdded?: boolean, hsMedication?: HSMedication, packedMedication?: HSPackedMedication) {
  const isInterim = packedMedication?.interim;

  if (isInterim && hsMedication?.packType === PackType.Blister) {
    return 'Blister'
  }
  if (packedMedication?.packType === PackType.Blister) {
    return 'Blister';
  }
  if (packedMedication?.packType === PackType.OriginalContainer) {
    return 'Original Container';
  }
  if (packedMedication?.packType === PackType.None) {
    return 'Other';
  }
  if (packedMedication?.packType === PackType.Packette) {
    return pharmacyAdded ? 'Pharmacy Added' : 'MPS Packed';
  }
}

// maps each packed medication to a list of dosed drugs for that medication
function getPreviousDosedDrugsForPackedPatientMedications(
    packedMedications: HSPackedMedication[],
    roundsStore: ReadonlyMap<string, HSDoseRound>,
): PreviousPackedMedicationAdministeredDrugsType[] {
  return packedMedications.map((medication) => {
    return {
      packedMedication: medication,
      mostRecentDosedDrugs: itiriri(roundsStore.values())
          .flat((round) => (round.administeredDoses ? round.administeredDoses : []))
          .flat(
              (dose) =>
                  dose.administeredDrugs?.map((drug) => {
                    return { drug: drug, doseTimestamp: dose.doseTimestamp };
                  }) ?? [],
          )
          .filter(
              (drug) =>
                  drug.drug.medicationId === medication.medicationId && drug.doseTimestamp === medication.doseTimestamp,
          )
          .map((drug) => drug.drug)
          .toArray()
          .sort((a, b) => DateUtils.compareDateStringsDescending(a.administeredAt, b.administeredAt)),
    };
  });
}
function getPreviousDoseTimeForPatient(
    patient: HSPatient | undefined,
    packedMedication: HSPackedMedication | undefined,
    packedDayStore: ReadonlyMap<string, HSPackedPatientDay>,

): HSPackedMedication | undefined {

  if (!packedMedication || !packedMedication.doseTimestamp || !patient) {
    return undefined;
  }
  let result: HSPackedMedication| undefined;
  let lastTime: undefined;
  itiriri(packedDayStore.values())
      // Only interested in packed patient days for this patient and before the requested time.
      .filter((ppd) => ppd && ppd.patientId === patient.hsId && DateUtils.compareDates(DateUtils.toOffsetlessDate(ppd.packDate!), startOfDay(DateUtils.toOffsetlessDate(packedMedication.doseTimestamp!))) === 0)
      .forEach((ppd) => {
        ppd.packedMedications?.forEach((pm) => {

          if (pm.medicationId === packedMedication.medicationId && pm.doseTimestamp && pm.doseTimestamp < packedMedication.doseTimestamp! && (!result || result.doseTimestamp! < pm.doseTimestamp)) {
            // The packed med is before the required date time, but after our current result.
            result = pm;
          }
        });
      });
  return result;
}

function getDosedDrugForPreviousDoseTime(
    patient: HSPatient | undefined,
    packedMedication: HSPackedMedication | undefined,
    roundsStore: ReadonlyMap<string, HSDoseRound>,
    packedDayStore: ReadonlyMap<string, HSPackedPatientDay>
): HSAdministeredDrug | undefined {

  if (!packedMedication || !patient) {
    return undefined;
  }
  const lastDose = getPreviousDoseTimeForPatient(patient, packedMedication, packedDayStore);
  if (!lastDose) {
    return undefined;
  }
  const previousDoses = getPreviousDosedDrugsForPackedPatientMedications([lastDose], roundsStore);
  if (previousDoses != null && previousDoses.length > 0) {
    return previousDoses[0].mostRecentDosedDrugs[0];
  }
}

function getReasonCode(code?: ReasonCode | AdHocReasonCode) {
  switch (code) {
  case ReasonCode.DoseSupplied:
    return 'Dose Supplied';
  case ReasonCode.Dosed:
  case AdHocReasonCode.Dosed:
    return 'Administered';
  case ReasonCode.DosedLate:
    return 'Administered Late';
  case ReasonCode.Absent:
  case AdHocReasonCode.Absent:
    return 'Absent';
  case ReasonCode.Omitted:
  case AdHocReasonCode.Omitted:
    return 'Omitted';
  case ReasonCode.Refused:
  case AdHocReasonCode.Refused:
    return 'Refused';
  case ReasonCode.Fasting:
    return 'Fasting';
  case ReasonCode.SelfAdministered:
    return 'Self Administered';
  case ReasonCode.NoStock:
  case AdHocReasonCode.NoStock:
    return 'No Stock';
  case ReasonCode.OnDoctorOrder:
    return 'On Doctor Order';
  case ReasonCode.Leave:
    return 'Leave (Absent)';
  case ReasonCode.Withheld:
  case AdHocReasonCode.Withheld:
    return 'Withheld';
  case ReasonCode.Ceased:
    return 'Ceased';
  case ReasonCode.CourseComplete:
    return 'Course Complete';
  case ReasonCode.Hospital:
    return 'Hospital (Absent)';
  case ReasonCode.Vomiting:
  case AdHocReasonCode.Vomiting:
    return 'Vomiting';
  case ReasonCode.RequiresRn:
    return 'Requires RN';
  case ReasonCode.ExcludePatient:
    return 'Exclude Resident';
  case ReasonCode.Timeout:
    return 'Timeout';
  case ReasonCode.ForceClose:
    return 'Force Close';
  case ReasonCode.Other:
  case AdHocReasonCode.Other:
    return 'Other';
  default:
    return 'Unknown';
  }
}

function generateAdministeredDrug(
    packedMedication: HSPackedMedication | HSPackedPrnMedication,
    drug: HSDrug,
    reasonCode: ReasonCode,
    commentTexts: string[],
    user: User,
    confirmationInitials?: string,
    confirmationUserId?: number,
    dose?: number,
    patchLocation?: PatchLocationDTO,
): HSAdministeredDrug {
  return {
    reasonCode: reasonCode,
    medicationId: packedMedication.medicationId,
    medicationVersion: packedMedication?.medicationVersion,
    chartedDosage: packedMedication?.dosage,
    administeredDosage: dose ?? packedMedication?.dosage,
    drugCode: drug.drugCode,
    createdAt: DateUtils.fromDate(new Date()),
    lastUpdatedAt: DateUtils.fromDate(new Date()),
    administeredAt: DateUtils.fromDate(new Date()),
    administeredBySubjectId: user.profile.sub,
    administeredDrugComments: commentTexts.map((comment) => ({
      createdAt: DateUtils.fromDate(new Date()),
      lastUpdatedAt: DateUtils.fromDate(new Date()),
      // Will be properly set on sync up but is required locally
      lastUpdatedBySubjectId: user.profile.sub,
      commentText: comment,
    })),
    confirmationInitials: confirmationInitials,
    confirmationUserId: confirmationUserId,
    patchLocationNumber: patchLocation?.LocationNumber,
    patchLocationDescription: patchLocation?.PatchArea,
    active: true,
  };
}

function generateAdministeredAdHocDrug(
    dose: number,
    drug: HSDrug,
    reasonCode: AdHocReasonCode,
    commentTexts: string[],
    user: User,
    confirmationInitials?: string,
    confirmationUserId?: number
): HSAdministeredAdHocDrug {
  return {
    reasonCode: reasonCode,
    administeredDosage: dose,
    drugCode: drug.drugCode,
    drugId: drug.hsId,
    createdAt: DateUtils.fromDate(new Date()),
    lastUpdatedAt: DateUtils.fromDate(new Date()),
    administeredAt: DateUtils.fromDate(new Date()),
    administeredBySubjectId: user.profile.sub,
    // eslint-disable-next-line sonarjs/no-identical-functions
    administeredAdHocDrugComments: commentTexts.map((comment) => ({
      createdAt: DateUtils.fromDate(new Date()),
      lastUpdatedAt: DateUtils.fromDate(new Date()),
      lastUpdatedBySubjectId: user.profile.sub,
      commentText: comment,
    })),
    confirmationInitials: confirmationInitials,
    confirmationUserId: confirmationUserId
  };
}

// Fetches information in a usable format for all notes from patients in a facility group for note displays in UI
function getDisplayableNoteInfosForFacilityGroupId(
    facilityGroupId: string,
    facilitiesStore: ReadonlyMap<string, HSFacility>,
    patientsStore: ReadonlyMap<string, HSPatient>,
    roundsStore: ReadonlyMap<string, HSDoseRound>,
    drugsStore: ReadonlyMap<string, HSDrug>,
    packedPatientDaysStore: ReadonlyMap<string, HSPackedPatientDay>,
    packedPatientPrnsStore: ReadonlyMap<string, HSPackedPatientPrnMedication>,
): DisplayablePatientNoteInfo[] {
  const patientUtils = new PatientUtils({
    packedDayStore: packedPatientDaysStore,
    roundStore: roundsStore,
    packedPrnStore: packedPatientPrnsStore,
    facilityStore: facilitiesStore,
    drugStore: drugsStore,
    testResultsStore: new Map(),
    patientStore: patientsStore,
    patchObservationStore: new Map(),
    facilityGroupConfigurationStore: new Map(),
    syringeDriverActivityStore: new Map(),
  });

  const facilities = itiriri(facilitiesStore.values())
      .toArray()
      .filter((facility) => facility.facilityGroupId?.toString() === facilityGroupId);

  const patients = patientUtils
      .getActivePatients()
      .filter((patient) => facilities.some((facility) => facility.hsId === patient.facility) ?? false)
      .toArray();
  const noteInfos = patients.flatMap((patient) =>
      getDisplayableNoteInfosForPatient(
          patient,
          roundsStore,
          drugsStore,
          patientUtils.findPackedPatientPrnMedications(patient.hsId!)?.packedPrnMedications ?? [],
          packedPatientDaysStore,
      ),
  );

  // Output data is sorted by date
  return noteInfos.sort((a, b) => b.date.valueOf() - a.date.valueOf());
}

type DrugNote =
    | HSAdministeredDrugComment
    | HSAdministeredDrugOutcome
    | HSAdministeredAdHocDrugComment
    | HSAdministeredAdHocDrugOutcome;

// Fetches information in a usable format for all notes from a patient for note displays in UI
// eslint-disable-next-line sonarjs/cognitive-complexity
function getDisplayableNoteInfosForPatient(
    patient: HSPatient,
    roundsStore: ReadonlyMap<string, HSDoseRound>,
    drugsStore: ReadonlyMap<string, HSDrug>,
    patientPrnMedications: HSPackedPrnMedication[],
    packedPatientDays: ReadonlyMap<string, HSPackedPatientDay>,
    noteType?: string,
): DisplayablePatientNoteInfo[] {
  // eslint-disable-next-line sonarjs/cognitive-complexity
  function getDrugNoteTitle(
      administeredDose: HSAdministeredDose,
      drugCode: string,
      reasonCode: ReasonCode | AdHocReasonCode,
      isOutcome?: boolean,
      isAdHoc?: boolean,
  ) {
    // drug codes act as an additional unique identifier
    const drug = itiriri(drugsStore.values()).find((drug) => drug.drugCode === drugCode);
    const isPrn = !administeredDose.doseTimestamp;
    const packedMedications = itiriri(packedPatientDays.values())
        .filter((p) => p.patientId === patient.hsId)
        .toArray()
        .flatMap((p) => p.packedMedications ?? []);
    const medication = packedMedications.find((p) => p.drugHsId === drug?.hsId) ?? {};
    return `${
        isAdHoc ? 'NIM' : isPrn ? 'PRN' : drug ? getMedicationTypeString(medication, drug) : 'Unknown Medication'
    } ${isOutcome ? 'outcome' : `${getReasonCode(reasonCode)} note`} for ${drug?.genericName}`;
  }
  // Creates the DisplayablePatientNoteInfos for HSPatientProgressNotes
  const displayableProgressNotes: DisplayablePatientNoteInfo[] =
      patient.patientProgressNotes
          ?.filter(
              (progressNote) =>
                  progressNote.date && progressNote.createdBySubjectId && progressNote.comment && progressNote.subject,
          )
          ?.map((progressNote) => ({
            patient: patient,
            date: DateUtils.toDate(progressNote.date!),
            comment: progressNote.comment!,
            attachmentReference: progressNote.attachmentReference!,
            updatedByLogin: progressNote.createdByLogin!,
            updatedBySubjectId: progressNote.createdBySubjectId,
            title: progressNote.subject!,
          })) ?? [];
  // Find the doses that apply to the given patient
  const patientDoses = itiriri(roundsStore.values())
      .flat((round) => round.administeredDoses ?? [])
      .filter((dose) => dose.patientId === patient.hsId);
  // Creates the DisplayablePatientNoteInfos for drug comments and outcomes
  const drugNotes: Array<{ item: DrugNote; title: string; drug: HSAdministeredDrug | HSAdministeredAdHocDrug }> =
      patientDoses
          .flat((dose) => [
            ...(dose.administeredDrugs?.flatMap((d) => [
              ...(d.administeredDrugComments?.map((item) => ({
                item: item,
                title: getDrugNoteTitle(dose, d.drugCode!, d.reasonCode!),
                drug: d,
              })) ?? []),
              ...(d.administeredDrugOutcomes?.map((item) => ({
                item: item,
                title: getDrugNoteTitle(dose, d.drugCode!, d.reasonCode!, true),
                drug: d,
              })) ?? []),
            ]) ?? []),
            ...(dose.administeredAdHocDrugs?.flatMap((d) => [
              ...(d.administeredAdHocDrugComments?.map((item) => ({
                item: item,
                // TODO: Update when SS enables AdHocReasonCode support
                title: getDrugNoteTitle(dose, d.drugCode!, AdHocReasonCode.Dosed, false, true),
                drug: d,
              })) ?? []),
              ...(d.administeredAdHocDrugOutcomes?.map((item) => ({
                item: item,
                title: getDrugNoteTitle(dose, d.drugCode!, AdHocReasonCode.Dosed, true, true),
                drug: d,
              })) ?? []),
            ]) ?? []),
          ])
          .toArray();
  const displayableDrugNotes: DisplayablePatientNoteInfo[] =
      drugNotes
          .filter((note) => note.item.lastUpdatedAt && note.item.commentText && note.item.lastUpdatedByLogin)
          .map((note) => ({
            patient: patient,
            date: DateUtils.toDate(note.item.createdAt!),
            comment: note.item.commentText!,
            attachmentReference: '',
            updatedByLogin: note.item.lastUpdatedByLogin!,
            updatedBySubjectId: note.item.lastUpdatedBySubjectId,
            title: note.title!,
            administeredDrug: note.drug,
          })) ?? [];
  let groupedNotesArray: DisplayablePatientNoteInfo[] = [];
  switch (noteType) {
  case 'Drug Administration Notes':
    groupedNotesArray = [...displayableDrugNotes];
    break;
  case 'Patient Progress Notes':
    groupedNotesArray = [...displayableProgressNotes.filter((note) => note.title !== patientProfileChangeString)];
    break;
  case 'Patient Notes with Attachment':
      groupedNotesArray = [...displayableProgressNotes.filter((note) => note.attachmentReference?.length>0)];
      break;
  case patientProfileChangeString:
    groupedNotesArray = [...displayableProgressNotes.filter((note) => note.title === patientProfileChangeString)];
    break;
  default:
    groupedNotesArray = [...displayableDrugNotes, ...displayableProgressNotes];
    break;
  }
  // The outputting notes should be sorted by date from most recent
  return groupedNotesArray.sort((a, b) => b.date.valueOf() - a.date.valueOf());
}

// Works out the medication type and outputs a string description
function getMedicationTypeString(
    packedMedication: HSPackedMedication | HSPackedPrnMedication | HSMedication,
    drug: HSDrug,
) {
  switch (packedMedication.medicationType) {
  case MedicationType.Prn:
    return 'PRN';
  case MedicationType.ShortCourse:
    return 'Short course medication';
  case MedicationType.Regular:
    if (packedMedication.route?.code === 'SID') {
      return 'Syringe driver';
    }
    if (PatchUtils.isPatch(drug)) {
      return 'Patch';
    }
    return 'Regular';
  default:
    return 'Unknown Type';
  }
}

function medicationInSecondCheckSettings(
    drugId?: number,
    routeCode?: string | null,
    settings?: ListSecondCheckSettingDto,
) {
  return (
      (!!drugId &&
          settings &&
          (settings.drugs?.some((drug) => drug.itemId === drugId.toString()) ||
              (!!routeCode && settings.routes?.some((settingRoute) => settingRoute.itemId === routeCode)))) ??
      false
  );
}

function getTotalDoseAmounts(medications: HSPackedMedication[] | HSPackedPrnMedication[]) {
  let totalDoseAmount = 0;

  medications.forEach((medication) => {
    totalDoseAmount = totalDoseAmount + (medication.dosage ?? 0);
  });

  return totalDoseAmount;
}

function getMedicationHeader(medicationList: MedicationGroup, isPatches?: boolean) {
  if (isPatches)
    return `${DateUtils.dateTo24HourTimeString(medicationList.categories.doseDate!)} Patches ${
        medicationList.categories.profileNumber ?? ''
    }(${
        getTotalDoseAmounts([
          ...medicationList.medications,
          ...(medicationList.scheduledActivityAction?.packedMed
              ? [medicationList.scheduledActivityAction.packedMed]
              : []),
        ]) ?? 0
    })`;

  return `${
      medicationList.categories.doseDate && DateUtils.dateTo24HourTimeString(medicationList.categories.doseDate)
  } ${medicationList.categories.packTypeString} ${
      medicationList.categories.profileNumber ? `${medicationList.categories.profileNumber} ` : ''
  }(${getTotalDoseAmounts(medicationList.medications) ?? 0})`;
}

function isMedicationSecondCheckable(secondCheckStore: ReadonlyMap<string, ListSecondCheckSettingDto>,
    drugStore: ReadonlyMap<string, HSDrug>,
    facilityGroupId: number, excludePackableMeds: boolean,
    medication: HSPackedMedication | HSPackedPrnMedication | NimAvailableDrugDto): boolean {
  const secondCheckSettings = secondCheckStore.get(facilityGroupId.toString());
  const roundUtils = new RoundUtils({
    packedDayStore: new Map(),
    patientStore: new Map(),
    drugStore: drugStore,
    packedPrnStore: new Map(),
    roundStore: new Map(),
    facilityStore: new Map(),
    facilityGroupConfigurationStore: new Map(),
    syringeDriverActivityStore: new Map(),
    patchObservationStore: new Map(),
  });

  if ("packType" in medication && excludePackableMeds) {
    // Packed med or packed prn med
    if (medication.packType === PackType.Blister || medication.packType === PackType.Packette) {
      // Ignore if it is packed and we are excluding packed meds
      return false;
    }

    if (medicationInSecondCheckSettings(medication.drugHsId, medication.route?.code, secondCheckSettings)) {
      // It is in the second check settings.
      return true;
    }
  }
  else {
    // Nim available drug dto
    if (medicationInSecondCheckSettings(medication.drugHsId, (<NimAvailableDrugDto>medication).route, secondCheckSettings)) {
      // It is in the second check settings.
      return true;
    }
  }


  // Schedule 8 (controlled) drugs implicitly require a second check
  return (!!medication.drugHsId && roundUtils.isControlledDrugId(medication.drugHsId));
}

function getSecondCheckableMedication(
    packedPrnStore: ReadonlyMap<string, HSPackedPatientPrnMedication>,
    secondCheckStore: ReadonlyMap<string, ListSecondCheckSettingDto>,
    drugStore: ReadonlyMap<string, HSDrug>,
    nimAvailableDrugStore: ReadonlyMap<string, NimAvailableDrugDto>,
    facilityGroupId: number,
    patient: HSPatient,
    packedMedication: HSPackedMedication[],
    patchPackedMedication: HSPackedMedication[],
    excludePackableMeds: boolean,          // This field is used for Med Comp Carer - med comp carer can administered controlled drugs without second checks, provided they are packed.
    roundSchedule?: RoundScheduleItem[],
): SecondCheckableMedication[] {
  const packedPatientPrnMedication = itiriri(packedPrnStore.values()).find((p) => p.patientId === patient.hsId) ?? {};

  const secondCheckableRoundItems =
      roundSchedule
          ?.filter(
              (item) =>
                  // Filter down to the schedule items for this patient
                  item.patient.hsId === patient?.hsId && isMedicationSecondCheckable(secondCheckStore, drugStore, facilityGroupId, excludePackableMeds, item.packedMedication)
          )
          .map((item) => ({ medication: item.packedMedication, id: item.packedMedication.hsId! })) ?? [];

  const secondCheckablePrns =
      packedPatientPrnMedication.packedPrnMedications
          ?.filter((item) => isMedicationSecondCheckable(secondCheckStore, drugStore, facilityGroupId, excludePackableMeds, item))
          .map((item) => ({ medication: item, id: item.hsId! })) ?? [];

  const nimList = itiriri(nimAvailableDrugStore.values()).filter(
      (nim) => nim.nimConsumers?.some((consumer) => consumer.hsPatientId === patient.hsId) ?? false,
  );

  const secondCheckableNims = nimList
      .filter((item) => isMedicationSecondCheckable(secondCheckStore, drugStore, facilityGroupId, excludePackableMeds, item))
      .map((nim) => ({ medication: nim, id: nim.id! }));

  const secondCheckableSyringeDrivers = packedMedication
      .filter((item) => isMedicationSecondCheckable(secondCheckStore, drugStore, facilityGroupId, excludePackableMeds, item))
      .map((driver) => ({ medication: driver!, id: driver!.hsId! }));

  const secondCheckablePatches = patchPackedMedication
      .filter(
          (med) =>
              med.drugHsId &&
              isMedicationSecondCheckable(secondCheckStore, drugStore, facilityGroupId, excludePackableMeds, med) &&
              !secondCheckableRoundItems.some((item) => item.id === med.hsId)
      )
      .map((patch) => ({ medication: patch!, id: patch!.hsId! }));

  return [
    ...secondCheckableRoundItems,
    ...secondCheckablePrns,
    ...secondCheckableNims,
    ...secondCheckableSyringeDrivers,
    ...secondCheckablePatches,
  ];
}

function getTestResultsForAdministeredDrug(
    testResultsStore: ReadonlyMap<string, MedisphereTestResult>,
    administeredDrug: HSAdministeredDrug | HSAdministeredAdHocDrug,
) {
  const isAdHoc = 'administeredAdHocDrugComments' in administeredDrug;
  function isTestResultForAdministeredDrug(test: MedisphereTestResult) {
    if (isAdHoc) {
      return (
          (!!test.administeredAdHocDrugClinicalSystemId &&
              test.administeredAdHocDrugClinicalSystemId === administeredDrug.clinicalSystemId) ||
          (!!test.administeredAdHocDrugId && administeredDrug.hsId === test.administeredAdHocDrugId)
      );
    } else {
      return (
          (!!test.administeredDrugClinicalSystemId &&
              test.administeredDrugClinicalSystemId === administeredDrug.clinicalSystemId) ||
          (!!test.administeredDrugId && administeredDrug.hsId === test.administeredDrugId)
      );
    }
  }

  return itiriri(testResultsStore.values())
      .filter((test) => isTestResultForAdministeredDrug(test))
      .toArray();
}

function getLatestAdministrationForScheduledDrug(
    roundsStore: ReadonlyMap<string, HSDoseRound>,
    packedMedication: HSPackedMedication,
) {
  if (!packedMedication.doseTimestamp) {
    return undefined;
  }
  const rounds = itiriri(roundsStore.values()).filter(
      (round) =>
          !!round.administeredDoses?.some(
              (dose) =>
                  dose.doseTimestamp === packedMedication.doseTimestamp &&
                  !!dose.administeredDrugs?.some((drug) => drug.medicationId === packedMedication.medicationId),
          ),
  );

  const drugs = rounds
      .toArray()
      .flatMap((round) => round.administeredDoses?.flatMap((dose) => dose.administeredDrugs))
      .filter((drug) => drug?.medicationId === packedMedication.medicationId);

  return drugs.sort((a, b) => DateUtils.compareDateStringsDescending(a?.administeredAt, b?.administeredAt))[0];
}

function getUpdateLogText(patient: HSPatient, fieldName: string) {
  switch (fieldName) {
  case 'suspended':
    return `Offsite Status updated to ${patient.suspended}`;
  case 'suspendedComment':
    return `Offsite Comment updated to ${patient.suspendedComment ?? 'N/A'}`;
  case 'specialInstructions':
    return `Special Instructions updated to ${patient.specialInstructions}`;
  case 'considerations':
    return `Special Considerations updated to ${getSpecialConsiderationText(patient.considerations!, patient.swallowDifficulty!)}`;
  case 'selfAdministered':
    return `Fully Self-Administered Status updated to ${patient.selfAdministered}`;
  default:
    return '';
  }
}

function getSpecialConsiderationText(specialConsiderations: HSConsiderations, swallowDifficulty: boolean) {
  const values = Object.values(specialConsiderations);
  let text = '';

  Object.keys(specialConsiderations).forEach((key, index) => {
    if (values[index]) {
      text = text.concat(`${_.startCase(key)}, `);
    }
  });

  if (swallowDifficulty) {
    text = text.concat(`Swallowing Difficulties`);
    return text;
  } else {
    //to get rid of the last comma and space (, )
    return text.substring(0, text.length - 2);
  }
}

async function addPatientProgressNote(
    patient: HSPatient,
    facilityGroupId: number,
    subject: string,
    comment: string,
    attachmentReference: string,
    patientsService: SyncPatients,
    user: User,
) {
  await patientsService.enqueue.createPatientProgressNote({
    type: 'progress-note-create',
    patientId: patient.hsId?.toString() ?? '',
    request: {
      patientProgressNote: {
        subject: subject,
        date: DateUtils.fromDate(new Date()),
        comment: comment,
        attachmentReference:attachmentReference,
        type: 'Nursing Note',
        createdBySubjectId: user.profile.sub,
      },
      patientId: patient.hsId!,
      facilityGroupId: facilityGroupId,
    },
  });
}

/**
 * This function is used to locally update the self administered status of all the medications in all
 * patient profiles when the user updates the self administered status of the resident.
 *
 * To be done for scenario B as described in https://gitlab.blitzm.systems/sigma-healthcare/sigma-mps-medmanager-application/-/issues/1309
 *
 * This is done to mimic the behavior on healthstream wherein if the self administered status of the resident changes
 * all the corresponding medications are updated to match the self administered status. This change is not reflected back
 * by the update patient end point and the user has to wait for sync down for changes to be reflected.
 * This has to be done locally to avoid incorrect administration if a round is started before sync down
 * @param patientMemoryCache cache object pointing to the indexed db storing patient data
 * @param patient updated patient
 */
// eslint-disable-next-line sonarjs/cognitive-complexity
async function updateAllPatientMedicationSAStatus(patientMemoryCache: MemoryCache<HSPatient>, patientID: string) {
  //fetch the patient with all updated details from storage
  const patient = await patientMemoryCache.get(patientID);
  if (patient === undefined) {
    return;
  }
  const medStatusMap = patient.selfAdministered ? undefined : getMedicationStatusMap(patient);
  let updatedPatient: HSPatient = patient;
  //get the medication in each profile if not undefined
  if (patient.patientProfiles) {
    //for each patient profile, update the all current medications
    for (const profile of patient.patientProfiles) {
      const updatedAllCurrentMedication =
          profile.allCurrentMedications?.map((medication): HSMedication => {
            //if map is not empty, check the status of corresponding medication
            if (medStatusMap !== undefined && medStatusMap.get(medication.hsId!)) {
              //in scenario B medication self administered status will be true
              return medication;
            }
            return {
              ...medication,
              selfAdministered: patient.selfAdministered,
            };
          }) ?? [];

      //update the self administered status of all medications in the profile
      const newProfile: HSPatientProfile = {
        ...profile,
        allCurrentMedications: updatedAllCurrentMedication,
      };

      //update the profile in the updated patient object
      updatedPatient = {
        ...updatedPatient,
        patientProfiles:
            updatedPatient.patientProfiles?.map((profile) => {
              if (profile.hsId === newProfile.hsId) {
                return newProfile;
              }
              return profile;
            }) ?? [],
      };
    }

    //update the indexed db and insert the updated patient into memory cache
    //this ensures that the patient is eager updated after the API call to reflect changes immediately
    await patientMemoryCache.set(patient.hsId!.toString(), updatedPatient);
  }
}

function getMedicationStatusMap(patient: HSPatient) {
  //first create a map from the progress notes related to medication status updates
  const medStatusMap = new Map<number, boolean>();

  //filter progress notes with Patient Profile Changes as subject and check if the comment contains medication
  //if yes, extract medication ID and SA status and store it in the map
  const medChangeNotes =
      patient.patientProgressNotes
          ?.sort((a, b) => DateUtils.compareDateStringsDescending(a.date, b.date, true))
          .filter((note) => {
            return note.subject === patientProfileChangeString && note.comment && note.comment.includes('med_id');
          }) ?? [];

  if (medChangeNotes.length === 0) {
    return undefined;
  }
  for (const note of medChangeNotes) {
    if (note.comment !== undefined && note.comment !== null) {
      /**
       * medStateArray contains the medication ID and self administered state as extracted from the note
       * as follows -
       * the note contains the info as -> (med_id - 1232312:true)
       * for the above medStateArray will look like ['1232231', 'true']
       */
      const medStatArray = note.comment
          .substring(note.comment.indexOf('-') + 1, note.comment.indexOf(')'))
          .trim()
          .split(':');

      medStatusMap.set(parseInt(medStatArray[0]), JSON.parse(medStatArray[1]));
    }
  }

  return medStatusMap;
}

function getActivePatches(
    patientId: number,
    selectedDate: Date,
    packedPatientDays: HSPackedPatientDay[],
    patchObservationStore: ReadonlyMap<string, HSPatchObservation>,
    drugStore: ReadonlyMap<string, HSDrug>,
) {
  return (
      packedPatientDays
          .flatMap((day) => day.packedMedications ?? [])
          //eslint-disable-next-line sonarjs/cognitive-complexity
          .filter((med) => {
            if (!med.drugHsId || !med.doseTimestamp || DateUtils.toDate(med.doseTimestamp) > endOfDay(selectedDate)) {
              return false;
            }
            const drug = drugStore.get(med.drugHsId.toString());

            if (!PatchUtils.isPatch(drug)) {
              return false;
            }

            const patchRemovalObservation = itiriri(patchObservationStore.values())
                .filter(
                    (obs) =>
                        PatchUtils.getPatchOperationData(obs)?.packedMedicationId === med.hsId &&
                        [PatchStatus.Removed, PatchStatus.FallenOff].includes(
                            PatchUtils.getPatchOperationData(obs)?.patchStatus ?? PatchStatus.ToSight,
                        ),
                )
                .sort((a, b) => DateUtils.compareDateStringsDescending(a.createdAt, b.createdAt))
                .toArray()[0];

            const patchReapplication = itiriri(patchObservationStore.values())
                .filter(
                    (obs) =>
                        PatchUtils.getPatchOperationData(obs)?.packedMedicationId === med.hsId &&
                        [PatchStatus.Reapplied].includes(
                            PatchUtils.getPatchOperationData(obs)?.patchStatus ?? PatchStatus.ToSight,
                        ),
                )
                .sort((a, b) => DateUtils.compareDateStringsDescending(a.createdAt, b.createdAt))
                .toArray()[0];

            const patchRemovalObservationDate = patchRemovalObservation?.createdAt
                ? DateUtils.toDate(patchRemovalObservation.createdAt)
                : undefined;

            if (
                patchRemovalObservationDate &&
                patchRemovalObservationDate < startOfDay(selectedDate) &&
                !(
                    patchReapplication?.createdAt &&
                    patchRemovalObservationDate < DateUtils.toDate(patchReapplication.createdAt)
                )
            ) {
              return false;
            }

            const patchRemovalPackedMedication = PatchUtils.getPatchRemovalPackedMedicationForPackedMedication(
                packedPatientDays,
                drug!,
                med,
                patientId,
            );
            return (
                patchRemovalPackedMedication?.doseTimestamp &&
                DateUtils.toDate(patchRemovalPackedMedication.doseTimestamp) >= startOfDay(selectedDate)
            );
          })
  );
}
