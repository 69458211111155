import {
  HSDoseRound,
  HSAdministeredDose,
  HSAdministeredDrug,
  HSAdministeredAdHocDrug,
} from 'server-openapi';
import { assert } from '../../core/utils/assertionUtils';

/**
 * Update a round with new data from the API.
 * Broadly speaking, this function tries to:
 * - Only update, not delete data
 * - Use the contents of the new round if there is a conflict
 *
 * @param oldRound - The existing round from the store
 * @param newRound - The new round with fresh data from the API
 * @returns a round with the data merged
 */
export function updateRound(oldRound: HSDoseRound, newRound: HSDoseRound): HSDoseRound {
  const updatedDoses = updateList(oldRound.administeredDoses, newRound.administeredDoses, updateDose, matchIds);
  const updatedSegments = updateList(
    oldRound.doseRoundSegments,
    newRound.doseRoundSegments,
    (x, y) => {
      return { ...x, ...y };
    },
    matchIds,
  );

  // We use oldRound.status because when a round is updated (a med gets administered) we change the round.status to "InProgress"
  // and write it into indexedDb (hence the oldRound) so app can behave as expected (Finish Round) ASAP.
  // File: FinishRoundPage.tsx Line:109
  const roundStatus = oldRound.status ? oldRound.status : newRound.status;

  return {
    ...oldRound,
    ...newRound,
    status: roundStatus,
    administeredDoses: updatedDoses,
    doseRoundSegments: updatedSegments,
  };
}

/**
 * Update an AdministeredDose with new data from the API.
 * Broadly speaking, this function tries to:
 * - Only update, not delete data
 * - Use the contents of the new dose if there is a conflict
 *
 * @param oldDose - The existing AdministeredDose from the store
 * @param newDose - The AdministeredDose with fresh data from the API
 * @returns a round with the data merged
 */
export function updateDose(oldDose: HSAdministeredDose, newDose: HSAdministeredDose): HSAdministeredDose {
  const updatedDrugs = updateList(oldDose.administeredDrugs, newDose.administeredDrugs, updateDrug, matchIds);
  const updatedAdhocDrugs = updateList(
    oldDose.administeredAdHocDrugs,
    newDose.administeredAdHocDrugs,
    updateAdhocDrug,
    matchIds,
  );
  const updatedDoseComments = updateList(
    oldDose.administeredDoseComments,
    newDose.administeredDoseComments,
    (x, y) => {
      return { ...x, ...y };
    },
    matchIds,
  );
  return {
    ...oldDose,
    ...newDose,
    administeredDrugs: updatedDrugs,
    administeredDoseComments: updatedDoseComments,
    administeredAdHocDrugs: updatedAdhocDrugs,
  };
}

export function updateDrug(oldDrug: HSAdministeredDrug, newDrug: HSAdministeredDrug): HSAdministeredDrug {
  const updatedDrugComments = updateList(
    oldDrug.administeredDrugComments,
    newDrug.administeredDrugComments,
    (x, y) => {
      return { ...x, ...y };
    },
    matchIds,
  );
  const updatedDrugOutcomes = updateList(
    oldDrug.administeredDrugOutcomes,
    newDrug.administeredDrugOutcomes,
    (x, y) => {
      return { ...x, ...y };
    },
    matchIds,
  );
  return {
    ...oldDrug,
    ...newDrug,
    administeredDrugComments: updatedDrugComments,
    administeredDrugOutcomes: updatedDrugOutcomes,
  };
}

export function updateAdhocDrug(
  oldDrug: HSAdministeredAdHocDrug,
  newDrug: HSAdministeredAdHocDrug,
): HSAdministeredAdHocDrug {
  const updatedDrugComments = updateList(
    oldDrug.administeredAdHocDrugComments,
    newDrug.administeredAdHocDrugComments,
    (x, y) => {
      return { ...x, ...y };
    },
    matchIds,
  );
  const updatedDrugOutcomes = updateList(
    oldDrug.administeredAdHocDrugOutcomes,
    newDrug.administeredAdHocDrugOutcomes,
    (x, y) => {
      return { ...x, ...y };
    },
    matchIds,
  );
  return {
    ...oldDrug,
    ...newDrug,
    administeredAdHocDrugComments: updatedDrugComments,
    administeredAdHocDrugOutcomes: updatedDrugOutcomes,
  };
}

/**
 * Merges two lists together using a matcher function and an update function
 * Invariants:
 * - If oldList and newList contain the same item, call updateWith to update the item
 * - The length of the list should be uniq(oldList) + uniq(newList) - that is, **no data should be lost**.
 * - In the event that newList is undefined, returns the old list.
 *
 * @param oldList - The old list to be updated
 * @param newList - The new list with
 * @param updateWith - How should conflicting items be updated?
 * @param matchBy - How should conflicts be identified?
 * @returns The updated list
 */
// eslint-disable-next-line sonarjs/cognitive-complexity -- this is a complicated function
export function updateList<T>(
  oldList: T[] | undefined | null,
  newList: T[] | undefined | null,
  updateWith: (oldItem: T, newItem: T) => T,
  matchBy: (oldItem: T, newItem: T) => boolean,
): T[] | undefined | null {
  // Both lists are undefined: return a new list.
  if (!oldList && !newList) return oldList;

  // If oldList is undefined or empty, but newList is not, then return newList
  if ((!oldList || oldList.length === 0) && newList) return newList;

  // If newList is undefined or empty, then just return oldList
  if ((!newList || newList.length === 0) && oldList) return oldList;

  // TODO: KR: For some reason TypeScript does not believe me that newlist and oldList are defined
  // Using assertionUtils.assertNotUndefined does not seem to work.
  // use ! as a temporary workaround.

  // The two lists are defined: identify conflicts and merge them
  const updatedItems: T[] = oldList!.map((oldItem) => {
    const newItem: T | undefined = newList!.find((newItem) => matchBy(oldItem, newItem));
    return newItem !== undefined ? updateWith(oldItem, newItem) : oldItem;
  });
  const newItems: T[] = newList!.filter((newItem) => !oldList!.some((oldItem) => matchBy(oldItem, newItem)));

  const updatedList = [...updatedItems, ...newItems];

  // Ensure we have not lost any items
  assert(() => updatedList.length >= oldList!.length);
  return updatedList;
}
interface HsObject {
  hsId?: number;
  clinicalSystemId?: string | null;
}

function matchIds<T extends HsObject>(x: T, y: T): boolean {
  return (
    (x.hsId !== undefined && x.hsId !== null && y.hsId !== undefined && y.hsId !== null && x.hsId === y.hsId) ||
    x.clinicalSystemId === y.clinicalSystemId
  );
}
