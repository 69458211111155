/* eslint-disable sonarjs/cognitive-complexity */
import {
  HSAdministeredDose,
  DoseRoundStatus,
  HSAdministeredDrug,
  HSPatient,
  HSDoseRound,
  HSAdministeredAdHocDrug,
  HSTestResult,
} from 'server-openapi';
import { AppServices } from '../../../../syncstream/SyncCenterProvider';
import { EnqueuedDrugCreateData, SyncRounds } from '../../../../syncstream/SyncRounds';
import { DrugUtils } from '../../../../syncstream/utils/DrugUtils';
import { DrugOutcome } from '../medicationInformation/DrugOutcomeDialog';
import { DateUtils } from '../../../../core/utils/dateUtils';
import {
  FilterDrugTypeControlled, FilterDrugTypeOther,
} from '../../../Dashboard/DashboardPage/DashboardPage';
import { SyncTestResults } from '../../../../syncstream/SyncTestResults';
import { getSelectedEnumMembers } from '../../../../core/utils/enumUtils';

export const DrugAdministrationUtils = {
  createRound,
  addOutcome,
  administerDrug,
};

async function createRound(
  userId: string,
  facilityId: number,
  facilityGroupId: number,
  syncRounds: SyncRounds,
  drugTypeControlled?: FilterDrugTypeControlled,
  drugTypeOther?: FilterDrugTypeOther,
  doses?: HSAdministeredDose[]
) {
  const now = DateUtils.fromDate(new Date());

  //Creates a description field out of the filter
  let description = '';
  if (!drugTypeOther && !drugTypeControlled) {
    description = 'All';
  } else {
    description += drugTypeControlled ? drugTypeControlled.toString() : '';
  }
  if (drugTypeOther) {
    if (description.length>0){
      description += ', '
    }
    description += getSelectedEnumMembers(FilterDrugTypeOther, drugTypeOther).join(', ');
  }

  const newRound = (
    await syncRounds.enqueue.createRound({
      type: 'round-create',
      request: {
        facilityGroupId: facilityGroupId,
        round: {
          description,
          createdAt: now,
          facilityId: facilityId,
          status: DoseRoundStatus.Pending,
          administeredDoses: doses,
        },
      },
    })
  ).value;

  return await syncRounds.enqueue.createRoundSegment({
    type: 'round-segment-create',
    doseRoundClinicalSystemId: newRound.clinicalSystemId!,
    request: {
      doseRoundId: -1,
      doseRoundStatus: DoseRoundStatus.Pending,
      doseRoundSegment: {
        createdAt: now,
        startedAt: now,
        lastUpdatedAt: now,
        startCommentText: 'Joined',
        active: true,
        startedBySubjectId: userId,
      },
      facilityGroupId: facilityGroupId,
    },
  });
}

async function administerDrug(
  userId: string,
  drug: HSAdministeredDrug | HSAdministeredAdHocDrug,
  patient: HSPatient,
  facilityGroupId: number,
  syncRounds: SyncRounds,
  syncTestResults: SyncTestResults,
  existingRound?: HSDoseRound,
  doseTimestamp = DateUtils.fromDate(new Date()),
  testResults?: HSTestResult[],
) {
  const facilityId = patient.facility;
  const now = DateUtils.fromDate(new Date());

  const round =
    existingRound ??
    (await createRound(
      userId,
      patient.facility!,
      facilityGroupId,
      syncRounds,
      DrugUtils.getDrugWarnings(drug).controlledDrug ? FilterDrugTypeControlled.Controlled : undefined,
      undefined,
      [],
    ));
  const scheduledDose = existingRound?.administeredDoses?.find(
    (dose) => dose.doseTimestamp === doseTimestamp && dose.patientId === patient?.hsId,
  );

  // Get the administered dose or create one for PRNs and NIMs
  const dose = scheduledDose
    ? scheduledDose
    : await syncRounds.enqueue.createAdministeredDose({
        type: 'dose-create',
        doseRoundClinicalSystemId: round.clinicalSystemId!,
        request: {
          administeredDose: {
            facilityId: facilityId,
            patientId: patient.hsId!,
            doseTimestamp: doseTimestamp,
            createdAt: now,
            lastUpdatedAt: now,
          },
          hsDoseRoundId: -1, // will be overridden on SyncUp
        },
      });

  if (!dose) {
    throw new Error('Failed to create dose for round');
  }

  const newDrugData: EnqueuedDrugCreateData | undefined =
    'medicationId' in drug
      ? await createAdministeredDrug(drug, syncRounds, round, dose)
      : 'administeredAdHocDrugComments' in drug
      ? await createAdministeredAdHocDrug(drug, syncRounds, round, dose)
      : undefined;

  if (!newDrugData) {
    throw new Error('Failed to identify administered drug as adhoc or non adhoc');
  }

  if (testResults) {
    const isAdHoc = 'administeredAdHocDrugComments' in drug;
    await processTestResults(
      testResults,
      facilityGroupId,
      patient,
      syncTestResults,
      round.clinicalSystemId!,
      newDrugData.administeredDrug.clinicalSystemId!,
      isAdHoc,
    );
  }

  // Complete the round if the administration was completed outside a round
  if (!existingRound) {
    await syncRounds.enqueue.completeRound({
      type: 'round-complete',
      clinicalSystemId: round.clinicalSystemId!,
      request: {
        facilityGroupId: facilityGroupId,
        doseRoundId: -1,
        completedAt: DateUtils.fromDate(new Date()),
      },
    });
  }

  return newDrugData;
}

async function createAdministeredDrug(
  drug: HSAdministeredDrug,
  syncRounds: SyncRounds,
  round: HSDoseRound,
  dose: HSAdministeredDose,
) {
  // Record the drug administration and dosage
  const res = await syncRounds.enqueue.createAdministeredDrug({
    type: 'drug-create',
    request: {
      administeredDrug: drug,
      hsAdministeredDoseId: -1,
    },
    doseRoundClinicalSystemId: round.clinicalSystemId!,
    administeredDoseClinicalSystemId: dose.clinicalSystemId!,
  });

  for (const comment of drug.administeredDrugComments ?? []) {
    await syncRounds.enqueue.createAdministeredDrugComment({
      type: 'drug-comment-create',
      request: {
        administeredDrugComment: comment,
        hsAdministeredDrugId: -1,
      },
      doseRoundClinicalSystemId: round.clinicalSystemId!,
      administeredDrugClinicalSystemId: res.administeredDrug.clinicalSystemId!,
    });
  }

  return res;
}

async function createAdministeredAdHocDrug(
  drug: HSAdministeredAdHocDrug,
  syncRounds: SyncRounds,
  round: HSDoseRound,
  dose: HSAdministeredDose,
) {
  // Record the drug administration and dosage
  const newDrugData = await syncRounds.enqueue.createAdministeredAdHocDrug({
    type: 'adhoc-drug-create',
    request: {
      administeredAdHocDrug: drug,
      hsAdministeredDoseId: -1,
    },
    doseRoundClinicalSystemId: round.clinicalSystemId!,
    administeredDoseClinicalSystemId: dose.clinicalSystemId!,
  });

  for (const comment of drug.administeredAdHocDrugComments ?? []) {
    await syncRounds.enqueue.createAdministeredAdHocDrugComment({
      type: 'adhoc-drug-comment-create',
      request: {
        administeredAdHocDrugComment: comment,
        hsAdministeredAdHocDrugId: -1,
      },
      doseRoundClinicalSystemId: round.clinicalSystemId!,
      administeredDrugClinicalSystemId: newDrugData.administeredDrug.clinicalSystemId!,
    });
  }

  return newDrugData;
}

async function addOutcome(
  outcome: DrugOutcome,
  administeredDrugClinicalSystemId: string,
  doseRoundClinicalSystemId: string,
  services: AppServices,
  userSubId: string,
  patient: HSPatient,
  facilityGroupId: number,
  isAdHoc: boolean,
) {
  isAdHoc
    ? await services.rounds.service.enqueue.createAdministeredAdHocDrugOutcome({
        type: 'create-admin-adhoc-drug-outcome',
        doseRoundClinicalSystemId: doseRoundClinicalSystemId,
        administeredAdHocDrugClinicalSystemId: administeredDrugClinicalSystemId,
        request: {
          administeredAdHocDrugOutcome: {
            commentText: outcome.comment,
            lastUpdatedBySubjectId: userSubId,
            createdAt: DateUtils.fromDate(new Date()),
            lastUpdatedAt: DateUtils.fromDate(new Date()),
          },
          hsAdministeredAdHocDrugId: -1,
        },
      })
    : await services.rounds.service.enqueue.createAdministeredDrugOutcome({
        type: 'create-admin-drug-outcome',
        doseRoundClinicalSystemId: doseRoundClinicalSystemId,
        administeredDrugClinicalSystemId: administeredDrugClinicalSystemId,
        request: {
          administeredDrugOutcome: {
            commentText: outcome.comment,
            lastUpdatedBySubjectId: userSubId,
            createdAt: DateUtils.fromDate(new Date()),
            lastUpdatedAt: DateUtils.fromDate(new Date()),
          },
          hsAdministeredDrugId: -1,
        },
      });

  await processTestResults(
    outcome.testResults,
    facilityGroupId,
    patient,
    services.testResults.service,
    doseRoundClinicalSystemId,
    administeredDrugClinicalSystemId,
    isAdHoc,
  );
}

async function processTestResults(
  testResults: HSTestResult[],
  facilityGroupId: number,
  patient: HSPatient,
  service: SyncTestResults,
  doseRoundClinicalSystemId: string,
  administeredDrugClinicalSystemId: string,
  isAdHoc: boolean,
) {
  for (const result of testResults) {
    await service.enqueue.createTestResult({
      type: 'test-result-create',
      administeredDrugRoundClinicalSystemId: doseRoundClinicalSystemId,
      medisphereTestResult: {
        ...result,
        administeredDrugClinicalSystemId: isAdHoc ? undefined : administeredDrugClinicalSystemId,
        administeredAdHocDrugClinicalSystemId: isAdHoc ? administeredDrugClinicalSystemId : undefined,
      },
      request: {
        facilityGroupId: facilityGroupId,
        testResult: { ...result, patientId: patient.hsId },
      },
    });
  }
}
