import {Dialog} from "../../../../kit/Dialog";
import React, {useState} from "react";
import {colors} from "../../../../kit/Theme/Theme";
import {Layout} from "../../../../kit/Layout";
import {Button} from "../../../../kit/Button";
import {TextArea} from "../../../../kit/Forms/TextArea";
import {FormGroup} from "../../../../kit/Forms/FormGroup";
import {Form} from "../../../../kit/Forms/Form";
import {useForm} from "react-hook-form";
import styled from "styled-components";

interface FinishRoundDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (formData: FinishRoundFormData) => void;
}

export interface FinishRoundFormData {
  finishRoundReason: string;
}

const FinishRoundDialog = (props: FinishRoundDialogProps) => {
  const [isOpen, setIsOpen] = useState(props.isOpen);
  const [isDisabled, setIsDisabled] = useState(true);
  const form = useForm<FinishRoundFormData>({
    mode: 'all',
    reValidateMode: 'onSubmit',
  });

  const validateNotes = (notes: string): string | undefined => {
    if (notes && notes.length > 0) {
      setIsDisabled(false);
      return;
    }

    setIsDisabled(true);
    return 'Reason for closing the round is required field';
  }

  const onSubmit = (data: FinishRoundFormData) => {
    props.onSubmit(data);
  }

  return (
    <Dialog closeButtonColor={colors.bg_green_400} open={isOpen} onRequestClose={props.onClose}>
      <Layout padding={0.5} gap={1}>
        <Form form={form} onSubmit={onSubmit}>
          <FormGroupContainer>
            <FormGroup label="Enter reason for finishing an incomplete round" fullWidth>
              <TextArea
                name="finishRoundReason"
                rows={3}
                fullWidth
                rules={{
                  validate: (notes: string) => validateNotes(notes)
                }}
                autoFocus
              />
            </FormGroup>
          </FormGroupContainer>
          <Layout horizontal gap={0.5} style={{justifyContent: 'flex-end', width: '40em'}}>
            <Button type="submit" style={{width: '200px'}} disabled={isDisabled}>
              Submit
            </Button>
          </Layout>
        </Form>
      </Layout>
    </Dialog>
  );
}

const FormGroupContainer = styled.div`
    margin: 2em 0 1em 0;
`

export default FinishRoundDialog;
