import itiriri from 'itiriri';
import React from 'react';
import { HSDrug, HSPackedMedication, HSPackedPrnMedication, PackType, HSPatient, HSDoseRound } from 'server-openapi';
import styled from 'styled-components';
import { Layout } from '../../../../kit/Layout';
import { Text } from '../../../../kit/Text';
import { useSyncCenter } from '../../../../syncstream/SyncCenterProvider';
import { useStore } from '../../../../core/storage/hooks/UseStore';
import { PatientCompactInfo } from '../PatientCompactInfo';
import { DrugAdministrationHistory } from './DrugAdministrationHistory';
import { DrugDetailLeftPanel } from './DrugDetailLeftPanel';
import { DrugUtils } from '../../../../syncstream/utils/DrugUtils';
import { Order } from '../../../../common/Order';
import { MedicationOrderStatusBox } from '../medicationInformation/MedicationOrderStatusBox';
import { Dialog } from '../../../../kit/Dialog';
import { OrderNotesProps } from '../../../FacilityManagement/FacilityOrdersPage/Components/OrderNotesDialog';
import { OrderResupplyProps } from '../../../FacilityManagement/FacilityOrdersPage/Components/OrderResupplyDialog';
import { checkIfMedicationIsSelfAdministered } from '../../../../syncstream/utils/PackedPatientDayUtils';
import { useApiUtils } from '../../../../syncstream/utils/hooks/useApiUtils';
import {colors} from "../../../../kit/Theme/Theme";

export interface IDrugDetailProps {
  patient: HSPatient;
  packedMedication?: HSPackedMedication | HSPackedPrnMedication;
  drug: HSDrug;
  isAdHoc: boolean;
  openOrderResupply?: (orderResupply: OrderResupplyProps) => void;
  openOrderNotes?: (orderNotes: OrderNotesProps) => void;
  findRecentOrder?: (hsPatientId: number, hsDrugCode: string) => Order | undefined;
}
export interface IDrugDetailDialogProps extends IDrugDetailProps {
  facilityGroupId: number;
  isOpen: boolean;
  setClosed: () => void;
  currentRound?: HSDoseRound;
}

export function DrugDetailDialog(props: IDrugDetailDialogProps) {
  const services = useSyncCenter();
  const patientUtils = useApiUtils().patients;
  const facilitiesStore = useStore(services.facilities.store).store;
  const facility = itiriri(facilitiesStore.values()).find((f) => f.hsId === props.patient.facility);
  const route = props.packedMedication?.route?.code ?? undefined;
  const directions = props.packedMedication?.directions ?? undefined;
  
  const administrationWarnings = DrugUtils.getAdministrationWarnings(
    props.drug,
    props.packedMedication,
    patientUtils.getHSMedicationFromPackedMedication(props.patient, props.packedMedication),
    props.packedMedication ? checkIfMedicationIsSelfAdministered(props.packedMedication, props.patient) : false,
  );

  return (
    <Dialog lazy closeButtonColor={'white'} open={props.isOpen} onRequestClose={() => props.setClosed()} size="full">
      <Layout align={'flex-start'} gap={1}>
        <PatientCompactInfo light patient={props.patient} facility={facility!} />
        <DialogContainer horizontal gap={2}>
          <DrugDetailLeftPanel
            drug={props.drug}
            packedMedication={props.packedMedication}
            facilityGroupId={props.facilityGroupId}
            patient={props.patient}
          />
          <Layout gap={1}>
            {props.packedMedication?.packType === PackType.OriginalContainer && (
              <ResupplyContainer>
                <DrugDetailText weight="bold" size={'medium'}>
                  Resupply Status
                </DrugDetailText>
                <MedicationOrderStatusBox
                  facilityGroupId={props.facilityGroupId}
                  hsPatientId={props.patient.hsId!}
                  hsMedicationId={props.packedMedication?.medicationId}
                  packType={props.packedMedication?.packType}
                  drug={props.drug}
                  route={route}
                  directions={directions}
                  administrationWarnings={administrationWarnings}
                  openOrderResupply={props.openOrderResupply}
                  openOrderNotes={props.openOrderNotes}
                  findRecentOrder={props.findRecentOrder}
                />
              </ResupplyContainer>
            )}
            <DrugDetailText weight="bold" size={'medium'}>
              Administration History
            </DrugDetailText>
            <DrugAdministrationHistory
              medicationType={props.packedMedication?.medicationType}
              isAdHoc={props.isAdHoc}
              isCeased={administrationWarnings.isCeasedMedication}
              patient={props.patient}
              drug={props.drug}
              currentRound={props.currentRound}
              facilityGroupId={props.facilityGroupId}
              packedMedication={props.packedMedication}
            />
          </Layout>
        </DialogContainer>
      </Layout>
    </Dialog>
  );
}

const DrugDetailText = styled(Text)`
  color: ${(p) => p.theme.backgrounds.default.fg};
    margin-bottom: 1em;
`;

const ResupplyContainer = styled.div`
  margin-bottom: 3em;
`;

const DialogContainer = styled(Layout)`
  padding: 1em;
  grid-template-columns: 3fr 5fr;
`;

const Line = styled.hr`
  border: 1px solid ${colors.bg_green_400};
  width: 100%;
`
